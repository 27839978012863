@font-face {
  font-family: "CDK-Icons";
  src: url("fonts/CDK-Icons.eot?ebe2go");
  src: url("fonts/CDK-Icons.eot?ebe2go#iefix") format("embedded-opentype"),
    url("fonts/CDK-Icons.ttf?ebe2go") format("truetype"),
    url("fonts/CDK-Icons.woff?ebe2go") format("woff"),
    url("fonts/CDK-Icons.svg?ebe2go#CDK-Icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Support for apps that already use the material class */
.material-icons {
  font-family: "CDK-Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

[class^="cdkicon-"],
[class*=" cdkicon-"],
.cdk-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "CDK-Icons" !important;
  speak: none;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Rules for sizing the icon. */
/* Support for apps that already use the material class, and also including CDK Icons */
.material-icons.md-18,
.cdk-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24,
.cdk-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36,
.cdk-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48,
.cdk-icons.md-48 {
  font-size: 48px;
}

.cdkicon-accessibility:before {
  content: "\e84e";
}
.cdkicon-accessible:before {
  content: "\e914";
}
.cdkicon-parts:before {
  content: "\c011";
}
.cdkicon-repair_order:before {
  content: "\c010";
}
.cdkicon-drop_off_early:before {
  content: "\c028";
}
.cdkicon-drop_off_late:before {
  content: "\c029";
}
.cdkicon-not_listed_location:before {
  content: "\c02a";
}
.cdkicon-pan_tool:before {
  content: "\e925";
}
.cdkicon-datavis_down:before {
  content: "\c013";
}
.cdkicon-datavis_up:before {
  content: "\c014";
}
.cdkicon-datavis_down_outline:before {
  content: "\c015";
}
.cdkicon-datavis_up_outline:before {
  content: "\c016";
}
.cdkicon-datavis_down_filled:before {
  content: "\c017";
}
.cdkicon-datavis_up_filled:before {
  content: "\c018";
}
.cdkicon-settings_applications:before {
  content: "\e8b9";
}
.cdkicon-filled_circle:before {
  content: "\c012";
}
.cdkicon-loaner_vehicle:before {
  content: "\c006";
}
.cdkicon-shuttle:before {
  content: "\c009";
}
.cdkicon-tire:before {
  content: "\c008";
}
.cdkicon-waiting:before {
  content: "\c007";
}
.cdkicon-business:before {
  content: "\e0af";
}
.cdkicon-add_comment:before {
  content: "\c005";
}
.cdkicon-service_appointment:before {
  content: "\c026";
}
.cdkicon-return_customer:before {
  content: "\c025";
}
.cdkicon-valet:before {
  content: "\c022";
}
.cdkicon-rental:before {
  content: "\c023";
}
.cdkicon-drop_off:before {
  content: "\c024";
}
.cdkicon-access_time:before {
  content: "\e192";
}
.cdkicon-circle_open:before {
  content: "\c027";
}
.cdkicon-circle_closed:before {
  content: "\c021";
}
.cdkicon-add_comment1:before {
  content: "\c00a";
}
.cdkicon-super_script:before {
  content: "\c019";
}
.cdkicon-sub_script:before {
  content: "\c020";
}
.cdkicon-assignment:before {
  content: "\e85d";
}
.cdkicon-calculator:before {
  content: "\c004";
}
.cdkicon-barcode:before {
  content: "\c001";
}
.cdkicon-cascade_arrow_right:before {
  content: "\c003";
}
.cdkicon-general_inventory:before {
  content: "\c002";
}
.cdkicon-3d_rotation:before {
  content: "\e84d";
}
.cdkicon-account_balance:before {
  content: "\e84f";
}
.cdkicon-account_balance_wallet:before {
  content: "\e850";
}
.cdkicon-account_circle:before {
  content: "\e853";
}
.cdkicon-add:before {
  content: "\e145";
}
.cdkicon-add_a_photo:before {
  content: "\e439";
}
.cdkicon-add_alert:before {
  content: "\e003";
}
.cdkicon-add_circle:before {
  content: "\e147";
}
.cdkicon-add_location:before {
  content: "\e567";
}
.cdkicon-add_shopping_cart:before {
  content: "\e854";
}
.cdkicon-add_to_photos:before {
  content: "\e39d";
}
.cdkicon-adjust:before {
  content: "\e39e";
}
.cdkicon-alarm:before {
  content: "\e855";
}
.cdkicon-alarm_add:before {
  content: "\e856";
}
.cdkicon-alarm_off:before {
  content: "\e858";
}
.cdkicon-alarm_on:before {
  content: "\e859";
}
.cdkicon-android:before {
  content: "\e85a";
}
.cdkicon-announcement:before {
  content: "\e85b";
}
.cdkicon-apps:before {
  content: "\e5c3";
}
.cdkicon-archive:before {
  content: "\e149";
}
.cdkicon-arrow_back:before {
  content: "\e5c4";
}
.cdkicon-arrow_downward:before {
  content: "\e5db";
}
.cdkicon-arrow_drop_down:before {
  content: "\e5c5";
}
.cdkicon-arrow_drop_down_circle:before {
  content: "\e5c6";
}
.cdkicon-arrow_drop_up:before {
  content: "\e5c7";
}
.cdkicon-arrow_forward:before {
  content: "\e5c8";
}
.cdkicon-arrow_upward:before {
  content: "\e5d8";
}
.cdkicon-aspect_ratio:before {
  content: "\e85c";
}
.cdkicon-assistant:before {
  content: "\e39f";
}
.cdkicon-assistant_photo:before {
  content: "\e3a0";
}
.cdkicon-attach_file:before {
  content: "\e226";
}
.cdkicon-attach_money:before {
  content: "\e227";
}
.cdkicon-attachment:before {
  content: "\e2bc";
}
.cdkicon-audiotrack:before {
  content: "\e3a1";
}
.cdkicon-autorenew:before {
  content: "\e863";
}
.cdkicon-backspace:before {
  content: "\e14a";
}
.cdkicon-beenhere:before {
  content: "\e52d";
}
.cdkicon-block:before {
  content: "\e14b";
}
.cdkicon-bluetooth:before {
  content: "\e1a7";
}
.cdkicon-bluetooth_audio:before {
  content: "\e60f";
}
.cdkicon-bluetooth_connected:before {
  content: "\e1a8";
}
.cdkicon-bluetooth_disabled:before {
  content: "\e1a9";
}
.cdkicon-bluetooth_searching:before {
  content: "\e1aa";
}
.cdkicon-blur_circular:before {
  content: "\e3a2";
}
.cdkicon-blur_linear:before {
  content: "\e3a3";
}
.cdkicon-blur_off:before {
  content: "\e3a4";
}
.cdkicon-blur_on:before {
  content: "\e3a5";
}
.cdkicon-bookmark:before {
  content: "\e866";
}
.cdkicon-bookmark_border:before {
  content: "\e867";
}
.cdkicon-border_all:before {
  content: "\e228";
}
.cdkicon-border_bottom:before {
  content: "\e229";
}
.cdkicon-border_clear:before {
  content: "\e22a";
}
.cdkicon-border_color:before {
  content: "\e22b";
}
.cdkicon-border_horizontal:before {
  content: "\e22c";
}
.cdkicon-border_inner:before {
  content: "\e22d";
}
.cdkicon-border_left:before {
  content: "\e22e";
}
.cdkicon-border_outer:before {
  content: "\e22f";
}
.cdkicon-border_right:before {
  content: "\e230";
}
.cdkicon-border_style:before {
  content: "\e231";
}
.cdkicon-border_top:before {
  content: "\e232";
}
.cdkicon-border_vertical:before {
  content: "\e233";
}
.cdkicon-brightness_1:before {
  content: "\e3a6";
}
.cdkicon-brightness_2:before {
  content: "\e3a7";
}
.cdkicon-brightness_3:before {
  content: "\e3a8";
}
.cdkicon-brightness_4:before {
  content: "\e3a9";
}
.cdkicon-brightness_5:before {
  content: "\e3aa";
}
.cdkicon-brightness_6:before {
  content: "\e3ab";
}
.cdkicon-brightness_7:before {
  content: "\e3ac";
}
.cdkicon-brightness_auto:before {
  content: "\e1ab";
}
.cdkicon-brightness_high:before {
  content: "\e1ac";
}
.cdkicon-brightness_low:before {
  content: "\e1ad";
}
.cdkicon-brightness_medium:before {
  content: "\e1ae";
}
.cdkicon-broken_image:before {
  content: "\e3ad";
}
.cdkicon-brush:before {
  content: "\e3ae";
}
.cdkicon-bubble_chart:before {
  content: "\e6dd";
}
.cdkicon-bug_report:before {
  content: "\e868";
}
.cdkicon-build:before {
  content: "\e869";
}
.cdkicon-burst_mode:before {
  content: "\e43c";
}
.cdkicon-cake:before {
  content: "\e7e9";
}
.cdkicon-call:before {
  content: "\e0b0";
}
.cdkicon-call_end:before {
  content: "\e0b1";
}
.cdkicon-call_made:before {
  content: "\e0b2";
}
.cdkicon-call_merge:before {
  content: "\e0b3";
}
.cdkicon-call_missed:before {
  content: "\e0b4";
}
.cdkicon-call_missed_outgoing:before {
  content: "\e0e4";
}
.cdkicon-call_received:before {
  content: "\e0b5";
}
.cdkicon-call_split:before {
  content: "\e0b6";
}
.cdkicon-camera:before {
  content: "\e3af";
}
.cdkicon-camera_alt:before {
  content: "\e3b0";
}
.cdkicon-camera_front:before {
  content: "\e3b1";
}
.cdkicon-camera_rear:before {
  content: "\e3b2";
}
.cdkicon-camera_roll:before {
  content: "\e3b3";
}
.cdkicon-cancel:before {
  content: "\e5c9";
}
.cdkicon-cast:before {
  content: "\e307";
}
.cdkicon-cast_connected:before {
  content: "\e308";
}
.cdkicon-center_focus_strong:before {
  content: "\e3b4";
}
.cdkicon-center_focus_weak:before {
  content: "\e3b5";
}
.cdkicon-chat:before {
  content: "\e0b7";
}
.cdkicon-chat_bubble:before {
  content: "\e0ca";
}
.cdkicon-check:before {
  content: "\e5ca";
}
.cdkicon-check_box:before {
  content: "\e834";
}
.cdkicon-check_box_outline_blank:before {
  content: "\e835";
}
.cdkicon-check_circle:before {
  content: "\e86c";
}
.cdkicon-chevron_left:before {
  content: "\e5cb";
}
.cdkicon-chevron_right:before {
  content: "\e5cc";
}
.cdkicon-clear:before {
  content: "\e14c";
}
.cdkicon-clear_all:before {
  content: "\e0b8";
}
.cdkicon-close:before {
  content: "\e5cd";
}
.cdkicon-closed_caption:before {
  content: "\e01c";
}
.cdkicon-cloud:before {
  content: "\e2bd";
}
.cdkicon-cloud_circle:before {
  content: "\e2be";
}
.cdkicon-cloud_done:before {
  content: "\e2bf";
}
.cdkicon-cloud_download:before {
  content: "\e2c0";
}
.cdkicon-cloud_off:before {
  content: "\e2c1";
}
.cdkicon-cloud_queue:before {
  content: "\e2c2";
}
.cdkicon-cloud_upload:before {
  content: "\e2c3";
}
.cdkicon-code:before {
  content: "\e86f";
}
.cdkicon-collections:before {
  content: "\e3b6";
}
.cdkicon-collections_bookmark:before {
  content: "\e431";
}
.cdkicon-color_lens:before {
  content: "\e3b7";
}
.cdkicon-colorize:before {
  content: "\e3b8";
}
.cdkicon-comment:before {
  content: "\e0b9";
}
.cdkicon-compare:before {
  content: "\e3b9";
}
.cdkicon-compare_arrows:before {
  content: "\e915";
}
.cdkicon-computer:before {
  content: "\e30a";
}
.cdkicon-contact_mail:before {
  content: "\e0d0";
}
.cdkicon-contact_phone:before {
  content: "\e0cf";
}
.cdkicon-contacts:before {
  content: "\e0ba";
}
.cdkicon-content_copy:before {
  content: "\e14d";
}
.cdkicon-content_cut:before {
  content: "\e14e";
}
.cdkicon-content_paste:before {
  content: "\e14f";
}
.cdkicon-control_point:before {
  content: "\e3ba";
}
.cdkicon-control_point_duplicate:before {
  content: "\e3bb";
}
.cdkicon-copyright:before {
  content: "\e90c";
}
.cdkicon-create:before {
  content: "\e150";
}
.cdkicon-create_new_folder:before {
  content: "\e2cc";
}
.cdkicon-credit_card:before {
  content: "\e870";
}
.cdkicon-crop:before {
  content: "\e3be";
}
.cdkicon-crop_16_9:before {
  content: "\e3bc";
}
.cdkicon-crop_3_2:before {
  content: "\e3bd";
}
.cdkicon-crop_5_4:before {
  content: "\e3bf";
}
.cdkicon-crop_7_5:before {
  content: "\e3c0";
}
.cdkicon-crop_din:before {
  content: "\e3c1";
}
.cdkicon-crop_free:before {
  content: "\e3c2";
}
.cdkicon-crop_landscape:before {
  content: "\e3c3";
}
.cdkicon-crop_original:before {
  content: "\e3c4";
}
.cdkicon-crop_portrait:before {
  content: "\e3c5";
}
.cdkicon-crop_rotate:before {
  content: "\e437";
}
.cdkicon-crop_square:before {
  content: "\e3c6";
}
.cdkicon-dashboard:before {
  content: "\e871";
}
.cdkicon-data_usage:before {
  content: "\e1af";
}
.cdkicon-date_range:before {
  content: "\e916";
}
.cdkicon-delete:before {
  content: "\e872";
}
.cdkicon-description:before {
  content: "\e873";
}
.cdkicon-desktop_windows:before {
  content: "\e30c";
}
.cdkicon-details:before {
  content: "\e3c8";
}
.cdkicon-developer_mode:before {
  content: "\e1b0";
}
.cdkicon-device_hub:before {
  content: "\e335";
}
.cdkicon-devices:before {
  content: "\e1b1";
}
.cdkicon-devices_other:before {
  content: "\e337";
}
.cdkicon-dialer_sip:before {
  content: "\e0bb";
}
.cdkicon-dialpad:before {
  content: "\e0bc";
}
.cdkicon-directions_bus:before {
  content: "\e530";
}
.cdkicon-directions_car:before {
  content: "\e531";
}
.cdkicon-directions_walk:before {
  content: "\e536";
}
.cdkicon-do_not_disturb:before {
  content: "\e612";
}
.cdkicon-do_not_disturb_off:before {
  content: "\e643";
}
.cdkicon-do_not_disturb_on:before {
  content: "\e644";
}
.cdkicon-dock:before {
  content: "\e30e";
}
.cdkicon-domain:before {
  content: "\e7ee";
}
.cdkicon-done:before {
  content: "\e876";
}
.cdkicon-drafts:before {
  content: "\e151";
}
.cdkicon-drag_handle:before {
  content: "\e25d";
}
.cdkicon-edit:before {
  content: "\e3c9";
}
.cdkicon-edit_location:before {
  content: "\e568";
}
.cdkicon-email:before {
  content: "\e0be";
}
.cdkicon-equalizer:before {
  content: "\e01d";
}
.cdkicon-error:before {
  content: "\e000";
}
.cdkicon-euro_symbol:before {
  content: "\e926";
}
.cdkicon-ev_station:before {
  content: "\e56d";
}
.cdkicon-event:before {
  content: "\e878";
}
.cdkicon-event_available:before {
  content: "\e614";
}
.cdkicon-event_busy:before {
  content: "\e615";
}
.cdkicon-event_note:before {
  content: "\e616";
}
.cdkicon-exit_to_app:before {
  content: "\e879";
}
.cdkicon-expand_less:before {
  content: "\e5ce";
}
.cdkicon-expand_more:before {
  content: "\e5cf";
}
.cdkicon-explore:before {
  content: "\e87a";
}
.cdkicon-exposure:before {
  content: "\e3ca";
}
.cdkicon-exposure_neg_1:before {
  content: "\e3cb";
}
.cdkicon-exposure_neg_2:before {
  content: "\e3cc";
}
.cdkicon-exposure_plus_1:before {
  content: "\e3cd";
}
.cdkicon-exposure_plus_2:before {
  content: "\e3ce";
}
.cdkicon-exposure_zero:before {
  content: "\e3cf";
}
.cdkicon-extension:before {
  content: "\e87b";
}
.cdkicon-face:before {
  content: "\e87c";
}
.cdkicon-fast_forward:before {
  content: "\e01f";
}
.cdkicon-fast_rewind:before {
  content: "\e020";
}
.cdkicon-favorite:before {
  content: "\e87d";
}
.cdkicon-favorite_border:before {
  content: "\e87e";
}
.cdkicon-feedback:before {
  content: "\e87f";
}
.cdkicon-file_download:before {
  content: "\e2c4";
}
.cdkicon-file_upload:before {
  content: "\e2c6";
}
.cdkicon-filter:before {
  content: "\e3d3";
}
.cdkicon-filter_1:before {
  content: "\e3d0";
}
.cdkicon-filter_2:before {
  content: "\e3d1";
}
.cdkicon-filter_3:before {
  content: "\e3d2";
}
.cdkicon-filter_4:before {
  content: "\e3d4";
}
.cdkicon-filter_5:before {
  content: "\e3d5";
}
.cdkicon-filter_6:before {
  content: "\e3d6";
}
.cdkicon-filter_7:before {
  content: "\e3d7";
}
.cdkicon-filter_8:before {
  content: "\e3d8";
}
.cdkicon-filter_9:before {
  content: "\e3d9";
}
.cdkicon-filter_9_plus:before {
  content: "\e3da";
}
.cdkicon-filter_b_and_w:before {
  content: "\e3db";
}
.cdkicon-filter_center_focus:before {
  content: "\e3dc";
}
.cdkicon-filter_drama:before {
  content: "\e3dd";
}
.cdkicon-filter_frames:before {
  content: "\e3de";
}
.cdkicon-filter_hdr:before {
  content: "\e3df";
}
.cdkicon-filter_list:before {
  content: "\e152";
}
.cdkicon-filter_none:before {
  content: "\e3e0";
}
.cdkicon-filter_tilt_shift:before {
  content: "\e3e2";
}
.cdkicon-filter_vintage:before {
  content: "\e3e3";
}
.cdkicon-find_in_page:before {
  content: "\e880";
}
.cdkicon-fingerprint:before {
  content: "\e90d";
}
.cdkicon-first_page:before {
  content: "\e5dc";
}
.cdkicon-flag:before {
  content: "\e153";
}
.cdkicon-flare:before {
  content: "\e3e4";
}
.cdkicon-flash_auto:before {
  content: "\e3e5";
}
.cdkicon-flash_off:before {
  content: "\e3e6";
}
.cdkicon-flash_on:before {
  content: "\e3e7";
}
.cdkicon-flight_land:before {
  content: "\e904";
}
.cdkicon-flight_takeoff:before {
  content: "\e905";
}
.cdkicon-flip:before {
  content: "\e3e8";
}
.cdkicon-folder:before {
  content: "\e2c7";
}
.cdkicon-folder_open:before {
  content: "\e2c8";
}
.cdkicon-folder_shared:before {
  content: "\e2c9";
}
.cdkicon-folder_special:before {
  content: "\e617";
}
.cdkicon-font_download:before {
  content: "\e167";
}
.cdkicon-format_align_center:before {
  content: "\e234";
}
.cdkicon-format_align_justify:before {
  content: "\e235";
}
.cdkicon-format_align_left:before {
  content: "\e236";
}
.cdkicon-format_align_right:before {
  content: "\e237";
}
.cdkicon-format_bold:before {
  content: "\e238";
}
.cdkicon-format_clear:before {
  content: "\e239";
}
.cdkicon-format_color_fill:before {
  content: "\e23a";
}
.cdkicon-format_color_reset:before {
  content: "\e23b";
}
.cdkicon-format_color_text:before {
  content: "\e23c";
}
.cdkicon-format_indent_decrease:before {
  content: "\e23d";
}
.cdkicon-format_indent_increase:before {
  content: "\e23e";
}
.cdkicon-format_italic:before {
  content: "\e23f";
}
.cdkicon-format_line_spacing:before {
  content: "\e240";
}
.cdkicon-format_list_bulleted:before {
  content: "\e241";
}
.cdkicon-format_list_numbered:before {
  content: "\e242";
}
.cdkicon-format_paint:before {
  content: "\e243";
}
.cdkicon-format_quote:before {
  content: "\e244";
}
.cdkicon-format_shapes:before {
  content: "\e25e";
}
.cdkicon-format_size:before {
  content: "\e245";
}
.cdkicon-format_strikethrough:before {
  content: "\e246";
}
.cdkicon-format_textdirection_l_to_r:before {
  content: "\e247";
}
.cdkicon-format_textdirection_r_to_l:before {
  content: "\e248";
}
.cdkicon-format_underlined:before {
  content: "\e249";
}
.cdkicon-forward:before {
  content: "\e154";
}
.cdkicon-forward_10:before {
  content: "\e056";
}
.cdkicon-forward_30:before {
  content: "\e057";
}
.cdkicon-forward_5:before {
  content: "\e058";
}
.cdkicon-fullscreen:before {
  content: "\e5d0";
}
.cdkicon-fullscreen_exit:before {
  content: "\e5d1";
}
.cdkicon-functions:before {
  content: "\e24a";
}
.cdkicon-gamepad:before {
  content: "\e30f";
}
.cdkicon-gesture:before {
  content: "\e155";
}
.cdkicon-get_app:before {
  content: "\e884";
}
.cdkicon-gif:before {
  content: "\e908";
}
.cdkicon-gps_fixed:before {
  content: "\e1b3";
}
.cdkicon-gps_not_fixed:before {
  content: "\e1b4";
}
.cdkicon-gps_off:before {
  content: "\e1b5";
}
.cdkicon-gradient:before {
  content: "\e3e9";
}
.cdkicon-grain:before {
  content: "\e3ea";
}
.cdkicon-graphic_eq:before {
  content: "\e1b8";
}
.cdkicon-grid_off:before {
  content: "\e3eb";
}
.cdkicon-grid_on:before {
  content: "\e3ec";
}
.cdkicon-group:before {
  content: "\e7ef";
}
.cdkicon-group_add:before {
  content: "\e7f0";
}
.cdkicon-hd:before {
  content: "\e052";
}
.cdkicon-hdr_off:before {
  content: "\e3ed";
}
.cdkicon-hdr_on:before {
  content: "\e3ee";
}
.cdkicon-hdr_strong:before {
  content: "\e3f1";
}
.cdkicon-hdr_weak:before {
  content: "\e3f2";
}
.cdkicon-headset:before {
  content: "\e310";
}
.cdkicon-headset_mic:before {
  content: "\e311";
}
.cdkicon-healing:before {
  content: "\e3f3";
}
.cdkicon-hearing:before {
  content: "\e023";
}
.cdkicon-help:before {
  content: "\e887";
}
.cdkicon-high_quality:before {
  content: "\e024";
}
.cdkicon-highlight:before {
  content: "\e25f";
}
.cdkicon-home:before {
  content: "\e88a";
}
.cdkicon-hourglass_empty:before {
  content: "\e88b";
}
.cdkicon-hourglass_full:before {
  content: "\e88c";
}
.cdkicon-http:before {
  content: "\e902";
}
.cdkicon-image:before {
  content: "\e3f4";
}
.cdkicon-image_aspect_ratio:before {
  content: "\e3f5";
}
.cdkicon-import_contacts:before {
  content: "\e0e0";
}
.cdkicon-import_export:before {
  content: "\e0c3";
}
.cdkicon-inbox:before {
  content: "\e156";
}
.cdkicon-indeterminate_check_box:before {
  content: "\e909";
}
.cdkicon-info:before {
  content: "\e88e";
}
.cdkicon-insert_chart:before {
  content: "\e24b";
}
.cdkicon-insert_comment:before {
  content: "\e24c";
}
.cdkicon-insert_drive_file:before {
  content: "\e24d";
}
.cdkicon-insert_emoticon:before {
  content: "\e24e";
}
.cdkicon-insert_photo:before {
  content: "\e251";
}
.cdkicon-iso:before {
  content: "\e3f6";
}
.cdkicon-keyboard:before {
  content: "\e312";
}
.cdkicon-keyboard_backspace:before {
  content: "\e317";
}
.cdkicon-keyboard_capslock:before {
  content: "\e318";
}
.cdkicon-keyboard_hide:before {
  content: "\e31a";
}
.cdkicon-keyboard_return:before {
  content: "\e31b";
}
.cdkicon-keyboard_tab:before {
  content: "\e31c";
}
.cdkicon-keyboard_voice:before {
  content: "\e31d";
}
.cdkicon-label:before {
  content: "\e892";
}
.cdkicon-label_outline:before {
  content: "\e893";
}
.cdkicon-landscape:before {
  content: "\e3f7";
}
.cdkicon-language:before {
  content: "\e894";
}
.cdkicon-laptop:before {
  content: "\e31e";
}
.cdkicon-laptop_chromebook:before {
  content: "\e31f";
}
.cdkicon-laptop_mac:before {
  content: "\e320";
}
.cdkicon-laptop_windows:before {
  content: "\e321";
}
.cdkicon-last_page:before {
  content: "\e5dd";
}
.cdkicon-launch:before {
  content: "\e895";
}
.cdkicon-layers:before {
  content: "\e53b";
}
.cdkicon-layers_clear:before {
  content: "\e53c";
}
.cdkicon-leak_add:before {
  content: "\e3f8";
}
.cdkicon-leak_remove:before {
  content: "\e3f9";
}
.cdkicon-lens:before {
  content: "\e3fa";
}
.cdkicon-lightbulb_outline:before {
  content: "\e90f";
}
.cdkicon-line_style:before {
  content: "\e919";
}
.cdkicon-line_weight:before {
  content: "\e91a";
}
.cdkicon-linear_scale:before {
  content: "\e260";
}
.cdkicon-link:before {
  content: "\e157";
}
.cdkicon-linked_camera:before {
  content: "\e438";
}
.cdkicon-list:before {
  content: "\e896";
}
.cdkicon-live_help:before {
  content: "\e0c6";
}
.cdkicon-local_atm:before {
  content: "\e53e";
}
.cdkicon-local_car_wash:before {
  content: "\e542";
}
.cdkicon-local_gas_station:before {
  content: "\e546";
}
.cdkicon-local_offer:before {
  content: "\e54e";
}
.cdkicon-local_shipping:before {
  content: "\e558";
}
.cdkicon-local_taxi:before {
  content: "\e559";
}
.cdkicon-location_city:before {
  content: "\e7f1";
}
.cdkicon-location_disabled:before {
  content: "\e1b6";
}
.cdkicon-location_off:before {
  content: "\e0c7";
}
.cdkicon-location_on:before {
  content: "\e0c8";
}
.cdkicon-location_searching:before {
  content: "\e1b7";
}
.cdkicon-lock_open:before {
  content: "\e898";
}
.cdkicon-lock_outline:before {
  content: "\e899";
}
.cdkicon-looks:before {
  content: "\e3fc";
}
.cdkicon-looks_3:before {
  content: "\e3fb";
}
.cdkicon-looks_4:before {
  content: "\e3fd";
}
.cdkicon-looks_5:before {
  content: "\e3fe";
}
.cdkicon-looks_6:before {
  content: "\e3ff";
}
.cdkicon-looks_one:before {
  content: "\e400";
}
.cdkicon-looks_two:before {
  content: "\e401";
}
.cdkicon-loop:before {
  content: "\e028";
}
.cdkicon-loupe:before {
  content: "\e402";
}
.cdkicon-low_priority:before {
  content: "\e16d";
}
.cdkicon-mail:before {
  content: "\e158";
}
.cdkicon-mail_outline:before {
  content: "\e0e1";
}
.cdkicon-map:before {
  content: "\e55b";
}
.cdkicon-memory:before {
  content: "\e322";
}
.cdkicon-menu:before {
  content: "\e5d2";
}
.cdkicon-merge_type:before {
  content: "\e252";
}
.cdkicon-message:before {
  content: "\e0c9";
}
.cdkicon-mic:before {
  content: "\e029";
}
.cdkicon-mic_none:before {
  content: "\e02a";
}
.cdkicon-mic_off:before {
  content: "\e02b";
}
.cdkicon-mode_comment:before {
  content: "\e253";
}
.cdkicon-mode_edit:before {
  content: "\e254";
}
.cdkicon-monetization_on:before {
  content: "\e263";
}
.cdkicon-money_off:before {
  content: "\e25c";
}
.cdkicon-monochrome_photos:before {
  content: "\e403";
}
.cdkicon-mood:before {
  content: "\e7f2";
}
.cdkicon-mood_bad:before {
  content: "\e7f3";
}
.cdkicon-more_horiz:before {
  content: "\e5d3";
}
.cdkicon-more_vert:before {
  content: "\e5d4";
}
.cdkicon-mouse:before {
  content: "\e323";
}
.cdkicon-move_to_inbox:before {
  content: "\e168";
}
.cdkicon-movie_creation:before {
  content: "\e404";
}
.cdkicon-movie_filter:before {
  content: "\e43a";
}
.cdkicon-multiline_chart:before {
  content: "\e6df";
}
.cdkicon-music_note:before {
  content: "\e405";
}
.cdkicon-nature:before {
  content: "\e406";
}
.cdkicon-nature_people:before {
  content: "\e407";
}
.cdkicon-navigation:before {
  content: "\e55d";
}
.cdkicon-near_me:before {
  content: "\e569";
}
.cdkicon-next_week:before {
  content: "\e16a";
}
.cdkicon-note:before {
  content: "\e06f";
}
.cdkicon-note_add:before {
  content: "\e89c";
}
.cdkicon-notifications:before {
  content: "\e7f4";
}
.cdkicon-notifications_active:before {
  content: "\e7f7";
}
.cdkicon-notifications_off:before {
  content: "\e7f6";
}
.cdkicon-notifications_paused:before {
  content: "\e7f8";
}
.cdkicon-open_in_browser:before {
  content: "\e89d";
}
.cdkicon-open_in_new:before {
  content: "\e89e";
}
.cdkicon-open_with:before {
  content: "\e89f";
}
.cdkicon-palette:before {
  content: "\e40a";
}
.cdkicon-panorama:before {
  content: "\e40b";
}
.cdkicon-panorama_fish_eye:before {
  content: "\e40c";
}
.cdkicon-panorama_horizontal:before {
  content: "\e40d";
}
.cdkicon-panorama_vertical:before {
  content: "\e40e";
}
.cdkicon-panorama_wide_angle:before {
  content: "\e40f";
}
.cdkicon-party_mode:before {
  content: "\e7fa";
}
.cdkicon-pause:before {
  content: "\e034";
}
.cdkicon-pause_circle_filled:before {
  content: "\e035";
}
.cdkicon-people:before {
  content: "\e7fb";
}
.cdkicon-person:before {
  content: "\e7fd";
}
.cdkicon-person_add:before {
  content: "\e7fe";
}
.cdkicon-phone:before {
  content: "\e0cd";
}
.cdkicon-phone_android:before {
  content: "\e324";
}
.cdkicon-phone_bluetooth_speaker:before {
  content: "\e61b";
}
.cdkicon-phone_forwarded:before {
  content: "\e61c";
}
.cdkicon-phone_in_talk:before {
  content: "\e61d";
}
.cdkicon-phone_iphone:before {
  content: "\e325";
}
.cdkicon-phone_locked:before {
  content: "\e61e";
}
.cdkicon-phone_missed:before {
  content: "\e61f";
}
.cdkicon-phone_paused:before {
  content: "\e620";
}
.cdkicon-phonelink:before {
  content: "\e326";
}
.cdkicon-phonelink_erase:before {
  content: "\e0db";
}
.cdkicon-phonelink_lock:before {
  content: "\e0dc";
}
.cdkicon-phonelink_off:before {
  content: "\e327";
}
.cdkicon-phonelink_ring:before {
  content: "\e0dd";
}
.cdkicon-phonelink_setup:before {
  content: "\e0de";
}
.cdkicon-photo:before {
  content: "\e410";
}
.cdkicon-photo_album:before {
  content: "\e411";
}
.cdkicon-photo_camera:before {
  content: "\e412";
}
.cdkicon-photo_filter:before {
  content: "\e43b";
}
.cdkicon-photo_library:before {
  content: "\e413";
}
.cdkicon-photo_size_select_actual:before {
  content: "\e432";
}
.cdkicon-photo_size_select_large:before {
  content: "\e433";
}
.cdkicon-photo_size_select_small:before {
  content: "\e434";
}
.cdkicon-picture_as_pdf:before {
  content: "\e415";
}
.cdkicon-pie_chart:before {
  content: "\e6c4";
}
.cdkicon-pie_chart_outlined:before {
  content: "\e6c5";
}
.cdkicon-pin_drop:before {
  content: "\e55e";
}
.cdkicon-place:before {
  content: "\e55f";
}
.cdkicon-play_arrow:before {
  content: "\e037";
}
.cdkicon-play_circle_filled:before {
  content: "\e038";
}
.cdkicon-plus_one:before {
  content: "\e800";
}
.cdkicon-portrait:before {
  content: "\e416";
}
.cdkicon-power:before {
  content: "\e63c";
}
.cdkicon-power_input:before {
  content: "\e336";
}
.cdkicon-present_to_all:before {
  content: "\e0df";
}
.cdkicon-print:before {
  content: "\e8ad";
}
.cdkicon-priority_high:before {
  content: "\e645";
}
.cdkicon-public:before {
  content: "\e80b";
}
.cdkicon-publish:before {
  content: "\e255";
}
.cdkicon-query_builder:before {
  content: "\e8ae";
}
.cdkicon-question_answer:before {
  content: "\e8af";
}
.cdkicon-radio:before {
  content: "\e03e";
}
.cdkicon-radio_button_checked:before {
  content: "\e837";
}
.cdkicon-radio_button_unchecked:before {
  content: "\e836";
}
.cdkicon-recent_actors:before {
  content: "\e03f";
}
.cdkicon-record_voice_over:before {
  content: "\e91f";
}
.cdkicon-redo:before {
  content: "\e15a";
}
.cdkicon-refresh:before {
  content: "\e5d5";
}
.cdkicon-remove:before {
  content: "\e15b";
}
.cdkicon-remove_circle:before {
  content: "\e15c";
}
.cdkicon-remove_from_queue:before {
  content: "\e067";
}
.cdkicon-remove_shopping_cart:before {
  content: "\e928";
}
.cdkicon-reorder:before {
  content: "\e8fe";
}
.cdkicon-repeat:before {
  content: "\e040";
}
.cdkicon-repeat_one:before {
  content: "\e041";
}
.cdkicon-replay:before {
  content: "\e042";
}
.cdkicon-replay_10:before {
  content: "\e059";
}
.cdkicon-replay_30:before {
  content: "\e05a";
}
.cdkicon-replay_5:before {
  content: "\e05b";
}
.cdkicon-reply:before {
  content: "\e15e";
}
.cdkicon-reply_all:before {
  content: "\e15f";
}
.cdkicon-report_problem:before {
  content: "\e8b2";
}
.cdkicon-restore:before {
  content: "\e8b3";
}
.cdkicon-ring_volume:before {
  content: "\e0d1";
}
.cdkicon-room:before {
  content: "\e8b4";
}
.cdkicon-rotate_90_degrees_ccw:before {
  content: "\e418";
}
.cdkicon-rotate_left:before {
  content: "\e419";
}
.cdkicon-rotate_right:before {
  content: "\e41a";
}
.cdkicon-router:before {
  content: "\e328";
}
.cdkicon-rss_feed:before {
  content: "\e0e5";
}
.cdkicon-save:before {
  content: "\e161";
}
.cdkicon-schedule:before {
  content: "\e8b5";
}
.cdkicon-school:before {
  content: "\e80c";
}
.cdkicon-screen_lock_landscape:before {
  content: "\e1be";
}
.cdkicon-screen_lock_portrait:before {
  content: "\e1bf";
}
.cdkicon-screen_lock_rotation:before {
  content: "\e1c0";
}
.cdkicon-screen_rotationscreen_rotation:before {
  content: "\e1c1";
}
.cdkicon-search:before {
  content: "\e8b6";
}
.cdkicon-security:before {
  content: "\e32a";
}
.cdkicon-send:before {
  content: "\e163";
}
.cdkicon-sentiment_dissatisfied:before {
  content: "\e811";
}
.cdkicon-sentiment_neutral:before {
  content: "\e812";
}
.cdkicon-sentiment_satisfied:before {
  content: "\e813";
}
.cdkicon-sentiment_very_dissatisfied:before {
  content: "\e814";
}
.cdkicon-sentiment_very_satisfied:before {
  content: "\e815";
}
.cdkicon-settings:before {
  content: "\e8b8";
}
.cdkicon-settings_brightnesssettings_brightness:before {
  content: "\e8bd";
}
.cdkicon-settings_system_daydream:before {
  content: "\e1c3";
}
.cdkicon-share:before {
  content: "\e80d";
}
.cdkicon-shopping_cart:before {
  content: "\e8cc";
}
.cdkicon-short_text:before {
  content: "\e261";
}
.cdkicon-show_chart:before {
  content: "\e6e1";
}
.cdkicon-signal_cellular_4_bar:before {
  content: "\e1c8";
}
.cdkicon-signal_cellular_connected_no_internet_4_bar:before {
  content: "\e1cd";
}
.cdkicon-signal_cellular_no_sim:before {
  content: "\e1ce";
}
.cdkicon-signal_cellular_null:before {
  content: "\e1cf";
}
.cdkicon-signal_cellular_off:before {
  content: "\e1d0";
}
.cdkicon-skip_next:before {
  content: "\e044";
}
.cdkicon-skip_previous:before {
  content: "\e045";
}
.cdkicon-slideshow:before {
  content: "\e41b";
}
.cdkicon-slow_motion_video:before {
  content: "\e068";
}
.cdkicon-smartphone:before {
  content: "\e32c";
}
.cdkicon-sms:before {
  content: "\e625";
}
.cdkicon-sms_failed:before {
  content: "\e626";
}
.cdkicon-snooze:before {
  content: "\e046";
}
.cdkicon-sort:before {
  content: "\e164";
}
.cdkicon-sort_by_alpha:before {
  content: "\e053";
}
.cdkicon-space_bar:before {
  content: "\e256";
}
.cdkicon-speaker:before {
  content: "\e32d";
}
.cdkicon-speaker_phone:before {
  content: "\e0d2";
}
.cdkicon-spellcheck:before {
  content: "\e8ce";
}
.cdkicon-star:before {
  content: "\e838";
}
.cdkicon-star_border:before {
  content: "\e83a";
}
.cdkicon-star_half:before {
  content: "\e839";
}
.cdkicon-stay_current_landscape:before {
  content: "\e0d3";
}
.cdkicon-stay_current_portrait:before {
  content: "\e0d4";
}
.cdkicon-stay_primary_landscape:before {
  content: "\e0d5";
}
.cdkicon-stay_primary_portrait:before {
  content: "\e0d6";
}
.cdkicon-stop:before {
  content: "\e047";
}
.cdkicon-storage:before {
  content: "\e1db";
}
.cdkicon-straighten:before {
  content: "\e41c";
}
.cdkicon-strikethrough_s:before {
  content: "\e257";
}
.cdkicon-style:before {
  content: "\e41d";
}
.cdkicon-subdirectory_arrow_left:before {
  content: "\e5d9";
}
.cdkicon-subdirectory_arrow_right:before {
  content: "\e5da";
}
.cdkicon-subject:before {
  content: "\e8d2";
}
.cdkicon-subtitles:before {
  content: "\e048";
}
.cdkicon-supervisor_account:before {
  content: "\e8d3";
}
.cdkicon-swap_calls:before {
  content: "\e0d7";
}
.cdkicon-swap_horiz:before {
  content: "\e8d4";
}
.cdkicon-switch_camera:before {
  content: "\e41e";
}
.cdkicon-switch_video:before {
  content: "\e41f";
}
.cdkicon-sync:before {
  content: "\e627";
}
.cdkicon-sync_disabled:before {
  content: "\e628";
}
.cdkicon-sync_problem:before {
  content: "\e629";
}
.cdkicon-system_update:before {
  content: "\e62a";
}
.cdkicon-tab:before {
  content: "\e8d8";
}
.cdkicon-tablet:before {
  content: "\e32f";
}
.cdkicon-tablet_android:before {
  content: "\e330";
}
.cdkicon-tablet_mac:before {
  content: "\e331";
}
.cdkicon-tag_faces:before {
  content: "\e420";
}
.cdkicon-text_fields:before {
  content: "\e262";
}
.cdkicon-text_format:before {
  content: "\e165";
}
.cdkicon-textsms:before {
  content: "\e0d8";
}
.cdkicon-texture:before {
  content: "\e421";
}
.cdkicon-thumb_down:before {
  content: "\e8db";
}
.cdkicon-thumb_up:before {
  content: "\e8dc";
}
.cdkicon-thumbs_up_down:before {
  content: "\e8dd";
}
.cdkicon-timelapse:before {
  content: "\e422";
}
.cdkicon-timeline:before {
  content: "\e922";
}
.cdkicon-timer:before {
  content: "\e425";
}
.cdkicon-timer_10:before {
  content: "\e423";
}
.cdkicon-timer_3:before {
  content: "\e424";
}
.cdkicon-timer_off:before {
  content: "\e426";
}
.cdkicon-title:before {
  content: "\e264";
}
.cdkicon-toc:before {
  content: "\e8de";
}
.cdkicon-today:before {
  content: "\e8df";
}
.cdkicon-tonality:before {
  content: "\e427";
}
.cdkicon-touch_app:before {
  content: "\e913";
}
.cdkicon-transform:before {
  content: "\e428";
}
.cdkicon-trending_down:before {
  content: "\e8e3";
}
.cdkicon-trending_flat:before {
  content: "\e8e4";
}
.cdkicon-trending_up:before {
  content: "\e8e5";
}
.cdkicon-tune:before {
  content: "\e429";
}
.cdkicon-tv:before {
  content: "\e333";
}
.cdkicon-unarchive:before {
  content: "\e169";
}
.cdkicon-undo:before {
  content: "\e166";
}
.cdkicon-unfold_less:before {
  content: "\e5d6";
}
.cdkicon-unfold_more:before {
  content: "\e5d7";
}
.cdkicon-usb:before {
  content: "\e1e0";
}
.cdkicon-verified_user:before {
  content: "\e8e8";
}
.cdkicon-vertical_align_bottom:before {
  content: "\e258";
}
.cdkicon-vertical_align_center:before {
  content: "\e259";
}
.cdkicon-vertical_align_top:before {
  content: "\e25a";
}
.cdkicon-vibration:before {
  content: "\e62d";
}
.cdkicon-video_call:before {
  content: "\e070";
}
.cdkicon-videocam:before {
  content: "\e04b";
}
.cdkicon-videocam_off:before {
  content: "\e04c";
}
.cdkicon-view_array:before {
  content: "\e8ea";
}
.cdkicon-view_carousel:before {
  content: "\e8eb";
}
.cdkicon-view_column:before {
  content: "\e8ec";
}
.cdkicon-view_comfy:before {
  content: "\e42a";
}
.cdkicon-view_compact:before {
  content: "\e42b";
}
.cdkicon-view_day:before {
  content: "\e8ed";
}
.cdkicon-view_headline:before {
  content: "\e8ee";
}
.cdkicon-view_list:before {
  content: "\e8ef";
}
.cdkicon-view_module:before {
  content: "\e8f0";
}
.cdkicon-view_quilt:before {
  content: "\e8f1";
}
.cdkicon-view_stream:before {
  content: "\e8f2";
}
.cdkicon-view_week:before {
  content: "\e8f3";
}
.cdkicon-vignette:before {
  content: "\e435";
}
.cdkicon-visibility:before {
  content: "\e8f4";
}
.cdkicon-visibility_off:before {
  content: "\e8f5";
}
.cdkicon-voice_chat:before {
  content: "\e62e";
}
.cdkicon-voicemail:before {
  content: "\e0d9";
}
.cdkicon-volume_down:before {
  content: "\e04d";
}
.cdkicon-volume_mute:before {
  content: "\e04e";
}
.cdkicon-volume_off:before {
  content: "\e04f";
}
.cdkicon-volume_up:before {
  content: "\e050";
}
.cdkicon-wallpaper:before {
  content: "\e1bc";
}
.cdkicon-warning:before {
  content: "\e002";
}
.cdkicon-watch:before {
  content: "\e334";
}
.cdkicon-watch_later:before {
  content: "\e924";
}
.cdkicon-wb_auto:before {
  content: "\e42c";
}
.cdkicon-wb_cloudy:before {
  content: "\e42d";
}
.cdkicon-wb_incandescent:before {
  content: "\e42e";
}
.cdkicon-wb_iridescent:before {
  content: "\e436";
}
.cdkicon-wb_sunny:before {
  content: "\e430";
}
.cdkicon-widgets:before {
  content: "\e1bd";
}
.cdkicon-wifi:before {
  content: "\e63e";
}
.cdkicon-wifi_lock:before {
  content: "\e1e1";
}
.cdkicon-wifi_tethering:before {
  content: "\e1e2";
}
.cdkicon-work:before {
  content: "\e8f9";
}
.cdkicon-wrap_text:before {
  content: "\e25b";
}
.cdkicon-zoom_in:before {
  content: "\e8ff";
}
.cdkicon-zoom_out:before {
  content: "\e900";
}